import React from 'react'

import { Heading, PlusIcon, OutlinedText, Text, PricingTable, MobilePricing } from 'components'
import { PricingFeatures } from 'helpers'
import { x } from 'helpers'

const Pricing = () => {
  const bgImage = 'https://d27pgjik7gkkwi.cloudfront.net/backgroundDots.png'

  return (
    <x.main
      background={`url(${bgImage}) center bottom/300px 300px repeat-x`}
      backgroundColor='mvrkDarkerGray'
      position='relative'
    >
      <x.div pt={{ xs: '50px', sm: '100px' }} className='max-content-width' margin='0 auto'>
        <x.div py={{ xs: '2rem', md: '6rem' }} display='flex' flexDirection='column'>
          <x.div display='flex' alignItems='center'>
            <PlusIcon mr='10px' />
            <x.span color='primary'>Vx360 3D PLATFORM</x.span>
          </x.div>
          <Heading fontSize={{ xs: '2.25rem', sm: '3rem', md: '4.5rem' }} lineHeight={{ _: '90%' }} my='0.5rem'>
            <OutlinedText as='span' display='block'>
              A simple
            </OutlinedText>
            <x.span display='block'>price solution</x.span>
          </Heading>
          <Text w={{ xs: '100%', sm: '50%' }} color='mvrkLightGray'>
            Built to meet the needs of many different types of virtual experiences and events, we offer two key pricing
            packages for you to choose from.
          </Text>
        </x.div>
      </x.div>
      <x.div className='max-content-width' margin='0 auto' pb={{ sm: '3rem', md: '6rem' }}>
        <x.div display={{ xs: 'none', sm: 'block' }}>
          <PricingTable />
        </x.div>

        <x.div display={{ xs: 'block', sm: 'none' }} py='2rem'>
          <MobilePricing
            features={PricingFeatures.filter(feature => feature.standard)}
            heading='Standard'
            to='/contact'
            description='The introductory package that offers the standard features of Vx360, in its most simplistic yet turnkey form.'
          />

          <MobilePricing
            features={PricingFeatures.filter(feature => feature.premium)}
            heading='Premium'
            to='/contact'
            description='Inclusive of all standard and premium features but with a few extra perks and options for an even further customized experience.'
          />
        </x.div>
      </x.div>
    </x.main>
  )
}

export default Pricing
